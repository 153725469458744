.fixedNavigation {
    position: fixed;
    bottom: 0;

    width: 100%;
    height: 10vh;

    z-index: 100;

    background: $transparent_navigation;
}

.navContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 $margin_medium_h * 2;
}

/*****HAMBURGER*****/
.hamburger {
    width: 30px;
    height: 30px;

    margin-right: $margin_small_h;

    display: block;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;
    transition: transform 330ms ease-out;
}

.hamburger.open {
    transform: rotate(-45deg);
}

.line-menu {
    width: 100%;
    height: 4px;

    border-radius: 8px;
    background-color: $black;
}

.line-menu.half {
    width: 50%;
}

.line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.open .line-menu.start {
    transform: rotate(-90deg) translateX(3px);
    background-color: $black;
}

.open .line-menu {
    background-color: $black;
}

.line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
}

.open .line-menu.end {
    transform: rotate(-90deg) translateX(-3px);
    background-color: $black;
}

/***COLOR CHANGE***/
.dark-line-menu {
    width: 100%;
    height: 4px;

    border-radius: 8px;
    background-color: $white;
}

.dark-line-menu.half {
    width: 50%;
}

.dark-line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.dark-line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.open .dark-line-menu.start {
    transform: rotate(-90deg) translateX(3px);
    background-color: $white;
}

.open .dark-line-menu {
    background-color: $white;
}

.dark-line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
}

.open .dark-line-menu.end {
    transform: rotate(-90deg) translateX(-3px);
    background-color: $white;
}

/*****END*****/

/*****LINKS MENU*****/
.navItemsMobile.active {
    width: 100%;
    height: $margin_medium_v * 5;

    bottom: 10vh;
}

.navItemsMobile {
    position: absolute;
    overflow: hidden;
    right: 0;
    bottom: -10vh;

    width: 100%;
    height: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    padding: $margin_medium_v $margin_medium_h * 1.2;

    background: $transparent_navigation;
    transition: $globalTransition;
}

.darkNavItems {
    background: $black;
}

.navItemsMobile .linkNav {
    color: $black;
    text-decoration: none;
    transition: $globalTransition;
}

.navItemsMobile .linkNav.activeLink {
    color: $red;
    transition: $globalTransition;
}

.navItemsMobile .navItem {
    padding: calc($margin_small_v / 2) $margin_medium_h;
}

/***GreyOverlay***/

.greyOverlay {
    width: 0px;
    right: 0;
}

.greyOverlay.activeOverlay {
    width: 100vw;
    height: 100vh;

    bottom: 0;

    display: block;
    position: absolute;
    transition: all 0.3s ease-in;
    background-color: none;
    z-index: -1;
}
