@import '../Enums.styles.scss';

.customSelect {
    width: fit-content;
}

#selectLanguage {
    @include sora-font;
    font-size: 0.8rem;
    font-weight: 300;
}

.dropdownLang {
    appearance: none;
    border: 0;
    outline: 0;

    /* safari */
    -webkit-appearance: none;

    /* other styles for aesthetics */
    width: fit-content;

    padding: $margin_small_v * 1.3 $margin_medium_h;
    border: 1px solid $black;
    border-radius: 50px;

    color: $black;
    cursor: pointer;
    background-color: $white;
}

.darkDropdown {
    appearance: none;
    border: 0;
    outline: 0;

    /* safari */
    -webkit-appearance: none;

    /* other styles for aesthetics */
    width: fit-content;

    padding: $margin_small_v * 1.3 $margin_medium_h;
    border: 1px solid $white;
    border-radius: 50px;

    color: $white;
    cursor: pointer;
    background-color: $black;
}

.dropdownLang .labelDropdown:checked {
    color: rgba($color: $black, $alpha: 0.5);
}

.labelDropdown {
    color: $black;
}

.darkLabelDropdown {
    color: $white;
}

.darkDropdown option {
    color: $white;
}

/***MEDIA QUERY***/
@media screen and (min-width: 940px) {
    #selectLanguage {
        @include sora-font;
        font-size: 0.9rem;
        font-weight: 300;
    }

    .dropdownLang {
        padding: $margin_small_v * 1.3 $margin_small_h * 1.5;
    }

    .darkDropdown {
        padding: $margin_small_v * 1.3 $margin_small_h * 1.5;
    }
}
