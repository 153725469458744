.mobileWorkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 $margin_medium_h * 2;
    padding-top: $margin_medium_v;
}

.headTitleWorkMobile {
    @include h3;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 2.7rem * 1.3;

    margin-top: $margin_medium_v;
    margin-bottom: $margin_medium_v * 1.5;
}

.filterMobileBtnContainer {
    width: 100%;
    height: fit-content;

    margin-bottom: $margin_medium_v;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    & button {
        @include sora-font;
        font-size: 0.9rem;
        font-weight: 300;
        color: $black;

        border: 1px solid $black;
        border-radius: 50px;
        padding: $margin_small_v $margin_medium_h * 1.2;

        cursor: pointer;
        background-color: $white;
    }

    & .activeFilter {
        color: $white;
        border: 1px solid $red;
        background-color: $red;
    }
}

.gridContainer {
    width: 100%;
}

.contentWorkMobile {
    width: 100%;
    display: block;

    margin-bottom: $margin_large_v;
}

.imgContainerWorkMobile {
    width: 100%;
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: $margin_small_v;

    background-color: rgba($color: $black, $alpha: 0.1);
}

.imgWorkMobile {
    width: 250px;
    height: 200px;

    object-fit: contain;
}

.titleWorkMobile {
    border-bottom: 1px solid $black;

    @include h3;
    font-size: 2rem;
}

.underContentWorkMobile {
    margin-top: $margin_small_v * 2;
    margin-bottom: $margin_small_v * 2;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/***MEDIA QUERIES***/
@media (min-width: 768px) {
    .gridContainer {
        display: grid;
        gap: 0px 50px;
        grid-template-columns: 45% 45%;
    }
}

@media (min-width: 1200px) {
    .gridContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }
}
