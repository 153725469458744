@import '../Enums.styles.scss';
@import '../enums/Animations.styles.scss';

.projectCardContainer {
    width: 100%;

    padding: 0 $margin_medium_h * 2;
    padding-top: $margin_medium_v * 1.5;
    margin-bottom: $margin_large_v * 1.2;
}

.goBackContainer {
    width: 100%;
    display: flex;
    align-items: center;

    margin-bottom: $margin_medium_v;

    cursor: pointer;

    .arrowBackIcon {
        border: solid $red;
        border-width: 0 1.5px 1.5px 0;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);

        margin-right: $margin_small_h * 2.5;
    }

    span {
        @include p1;
        color: $red;
    }
}

.titleProjectCard {
    @include h3;
}

.infoContainer {
    display: flex;
    flex-direction: column;
}

.statsContainer {
    display: flex;
    flex-direction: column;

    margin-top: $margin_medium_v;
    margin-bottom: $margin_medium_v;

    gap: 2rem;
}

.statsItem {
    & h3 {
        @include p2;
        color: $grey;
        border-bottom: 0.5px solid $grey;
        padding-bottom: $margin_small_v;
        margin-bottom: $margin_small_v;
    }
}

.itemStats {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    gap: 0.6rem;
}

.descriptionContainer {
    margin-bottom: $margin_large_v;
}

.imagesProjectCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imgProjectCard {
    width: 300px;
    height: 250px;
}

.imgProjectCard img {
    width: 100%;
    height: 100%;

    object-fit: contain;
}

.imgProjectsCardContainer {
    margin-top: $margin_large_v;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/***MEDIA QUERIES***/
@media (min-width: 768px) and (max-width: 940px) {
    .goBackContainer {
        .arrowBackIcon {
            border-width: 0 2px 2px 0;
            padding: 5px;
        }

        span {
            @include h4;
            color: $red;
        }
    }

    .titleProjectCard {
        @include h2;
    }

    .statsItem {
        & h3 {
            @include p1;
            font-size: 0.9rem;
        }
    }

    .itemStats {
        & p {
            @include p1;
            font-size: 1.1rem;
        }
    }

    .descriptionContainer {
        @include p1;
        font-size: 1.1rem;
    }

    .imgProjectCard {
        width: 500px;
        height: 350px;

        object-fit: contain;
    }

    .imgProjectsCardContainer {
        gap: 2rem;
    }
}

@media (min-width: 940px) {
    .projectCardContainer {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding: $margin_large_v $margin_medium_h;
        margin-bottom: $margin_large_v;
    }

    .goBackContainer {
        transition: $globalTransition;
    }

    .goBackContainer:hover {
        transition: $globalTransition;

        .arrowBackIcon {
            border: solid $blue;
            border-width: 0 1.5px 1.5px 0;
            padding: 3px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);

            margin-right: $margin_small_h * 2.5;

            transition: $globalTransition;
        }

        span {
            @include p1;
            color: $blue;

            transition: $globalTransition;
        }
    }

    .contentCardContainer {
        width: 75%;
    }

    .titleProjectCard {
        @include h2;
        font-size: 3.8rem;
        line-height: 3.8rem * 1.3;
    }

    .statsContainer {
        display: flex;
        flex-direction: row;

        margin-bottom: $margin_medium_v * 2;
    }

    .statsItem {
        width: 33%;

        &:first-child {
            margin-left: 0;
        }

        & h3 {
            @include p2;
        }
    }
    .itemStats {
        & p {
            @include p1;
            font-size: 1.1rem;
        }
    }

    .descriptionContainer {
        @include p1;
        font-size: 0.9rem;
        line-height: 0.9rem * 1.8;
        font-weight: 300;
    }

    .imgProjectCard {
        width: 650px;
        height: 450px;

        object-fit: contain;
    }

    .imgProjectsCardContainer {
        gap: 3rem;
    }
}

@media (min-width: 1200px) {
    .projectCardContainer {
        margin-bottom: $margin_medium_v;
    }
    .titleProjectCard {
        @include h2;
        font-size: 4.5rem;
        line-height: 5.5rem * 1.3;
    }

    .statsItem {
        width: 30%;
        margin: 0 3%;

        &:first-child {
            margin-left: 0;
        }
    }

    .descriptionContainer {
        width: 70%;
    }

    .imgProjectCard {
        width: 1050px;
        height: 650px;
    }

    .imgProjectsCardContainer {
        gap: 4rem;
    }
}
