.desktopNavContainer {
    width: 100%;

    position: fixed;
    right: 0;
    bottom: 0;
    padding-left: 6%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    padding-top: $margin_small_v * 2;
    padding-bottom: $margin_medium_v * 1.2;

    background-color: $transparent_navigation;
    z-index: 100;
}

.imgNav {
    width: 40px;
    height: 40px;
}

.navItems {
    gap: 1.5rem;
}

.navItem {
    font-size: 0.9rem;
    padding: calc($margin_small_v / 2) $margin_small_h * 1.5;
}

.contactNavText {
    border: 1px solid $white;
    color: $white;
    background-color: $black;

    &.activeNav {
        border: 1px solid $white;
        color: $white;
    }
}
