@import '../Enums.styles.scss';

.loaderContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;

    overflow: hidden;
    z-index: 200;

    background-color: $black;
}

.loaderSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.clip {
    overflow-y: clip;
}

.titleText {
    color: $white;
    @include h3;
}

.spacer {
    width: 300px;
    height: 400px;
    position: relative;
}

.imgSpacer {
    width: 100%;
    height: 100%;

    object-fit: contain;
}

/***MEDIA QUERIES***/
@media (min-width: 768px) {
    .loaderSection {
        flex-direction: row;
    }

    .spacer {
        width: 250px;
        height: 300px;
    }
}

@media (min-width: 1200px) {
    .titleText {
        @include h2;
    }

    .spacer {
        width: 300px;
        height: 400px;
    }
}