@import '../Enums.styles.scss';
@import '../enums/Animations.styles.scss';

@import './navigation/MobileNav.styles.scss';

.contactNav {
    background-color: $black !important;
}

.imgNav {
    width: 30px;
    height: 30px;
}

.imgNav img {
    width: 100%;
    height: 100%;

    object-fit: contain;
}

ul,
li {
    list-style: none;
    text-transform: uppercase;
}

.navItems {
    display: flex;
    flex-direction: row;
    gap: 1.3rem;
}

.navItem {
    @include sora-font;
    font-size: 0.8rem;
    font-weight: 300;

    border: 1px solid $black;
    border-radius: 50px;
    padding: calc($margin_small_v / 2) $margin_medium_h;

    cursor: pointer;
    background-color: $white;
}

.activeNav {
    border: 1px solid $red;
    border-radius: 50px;
    color: $red;
    background-color: transparent;
}

.contactNavText {
    border: 1px solid $white;
    color: $white;
    background-color: $black;

    &.activeNav {
        border: 1px solid $white;
        color: $white;
    }
}

/***MEDIA QUERIES***/
@import './navigation/DesktopNav.styles.scss';
