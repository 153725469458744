.projectsDesktopContainer {
    width: 100vw;
    min-height: 100vh;
    max-height: fit-content;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: $margin_large_v * 2;
}

.projectsMapDesktop {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 0 $margin_large_h;
}

.headTitleWorkDesktop {
    @include h1;

    margin: $margin_large_v $margin_large_h;
    margin-bottom: $margin_medium_v * 2;

    text-align: center;
}

.filterBtnContainer {
    width: 100%;
    height: fit-content;

    margin-bottom: $margin_medium_v;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    & button {
        @include p1;
        color: $black;

        border: 1px solid $black;
        border-radius: 50px;
        padding: $margin_small_v * 2 $margin_medium_h - 1vw;

        cursor: pointer;
        background-color: $white;
    }

    & .activeFilter {
        color: $white;
        border: 1px solid $red;
        background-color: $red;
    }

    z-index: 50;
}

.filterDesktopContainer {
    margin-bottom: $margin_large_v;
}

.filterDesktopContainer button {
    @include p1;
    font-size: 0.95rem;
    line-height: 0.95rem * 1.3;
    color: $black;

    padding: $margin_small_v * 1.5 $margin_small_h * 1.5;
}

.projectsDesktopContainer table {
    width: 100%;
    border-collapse: collapse;
}

.projectsDesktopContainer thead {
    text-align: left;

    height: $margin_medium_v * 2;
    border-bottom: 1px solid $grey;

    @include p2;
    font-size: 0.9rem;
    line-height: 0.9rem * 1.8;
    color: $grey;
}

/***MODAL***/
.modalContainer {
    height: 350px;
    width: 400px;
    position: absolute;
    background-color: rgba($color: $black, $alpha: 0.2);
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalSlider {
    height: 100%;
    width: 100%;
    position: absolute;
    transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.modal {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal img {
    width: 400px;
    height: 350px;

    object-fit: contain;
}

.cursor,
.cursorLabel {
    position: absolute;
    width: 70px;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    @include p1;
    font-size: 0.9rem;
    line-height: 0.9rem * 1.3;
    color: $white;

    pointer-events: none;
    border-radius: 50%;
    background-color: $red;
    z-index: 2;
}

.cursorLabel {
    background-color: transparent;
}

/***PROJECT***/
.contentWorkDesktop {
    width: 100%;
    height: 100%;

    display: contents;
}

.project {
    width: 100%;
    height: $margin_large_v * 1.5;

    transform: translateY(25%);

    border-bottom: 1px solid $grey;
    cursor: pointer;
    transition: all 0.2s;
}

.project:last-of-type {
    border-bottom: 1px solid $grey;
}

.project:hover {
    opacity: 0.5;
}

.project:hover h2 {
    transform: translateX(-10px);
}

.project:hover p {
    transform: translateX(10px);
}

.project .titleDesktop {
    @include h3;
    font-size: 2rem;
    font-weight: 400;
}

.project p {
    transition: all 0.4s;

    @include p1;
}
