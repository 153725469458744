@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

/***COLORS***/
$white: #e5e7e6;
$red: #b60f46;
$blue: #70d6ff;
$black: #1c110a;
$grey: #858686;

$transparent_navigation: rgba(
    $color: $white,
    $alpha: 0.9,
);

/***Typography***/
@mixin sora-font {
    font-family: 'Sora', sans-serif;
}

@mixin basic {
    @include sora-font;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem * 1.8;
    word-spacing: 2px;
}

@mixin heading {
    @include sora-font;
    font-weight: 600;
    font-size: 5.5rem;
    line-height: 5.5rem * 1.3;
    word-spacing: 3px;
}

@mixin h1 {
    @include heading;
}

@mixin h2 {
    @include heading;
    font-size: 4.236rem;
    font-weight: 500;
    line-height: 4.236rem * 1.3;
}

@mixin h3 {
    @include heading;
    font-size: 2.618rem;
    font-weight: 500;
    line-height: 2.618rem * 1.3;
}

@mixin h4 {
    @include heading;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2rem;
}

@mixin p1 {
    @include basic;
}

@mixin p2 {
    @include basic;
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 0.8rem * 1.7;
}

@mixin p3 {
    @include basic;
    font-size: 0.382rem;
    font-weight: 300;
    line-height: 0.382rem * 1.7;
}

/***MARGINS***/
$margin_small_h: 1vw;
$margin_medium_h: 4vw;
$margin_large_h: 12vw;

$margin_small_v: 1vh;
$margin_medium_v: 4vh;
$margin_large_v: 12vh;

$margin_big: 325px;
