@import '../Enums.styles.scss';

.homePageContainer {
    width: 100%;
    height: 100vh;

    padding: 0 $margin_medium_h * 2;
    padding-top: $margin_large_v;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    overflow-y: hidden;
}

.headTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: fit-content;
}

.headTitle {
    margin: 0;

    color: $black;
    @include h3;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 2.7rem;
}

.gradientHeadTitle {
    background: -webkit-linear-gradient(315deg, $red 10%, $blue 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.functionsHead {
    @include p1;
    font-size: 0.9rem;

    margin-bottom: $margin_medium_v - 1vh;
}

.descriptionText {
    @include p2;
    font-size: 0.8rem;
}

.imgHeader {
    width: 250px;
    height: 300px;

    border-radius: 20px;
}

.imgHeader img {
    width: 100%;
    height: 100%;

    border-radius: 20px;
    object-fit: cover;
}

/***MEDIA QUERIES***/
@media (max-width: 940px) {
    .homePageContainer {
        margin-bottom: 10vh;
    }

    .secondHeadTitle {
        margin-top: $margin_small_v;
    }

    .imgHeader {
        width: 100%;

        text-align: center;

        margin-top: $margin_medium_v;
    }

    .imgHeader img {
        width: 250px;
    }
}

@media (min-width: 940px) {
    .homePageContainer {
        padding-top: 0;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .headTitleContainer {
        display: flex;
        flex-direction: column;
    }

    .headTitle {
        @include h3;
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 3.5rem * 1.5;
    }

    .secondHeadTitle {
        margin-top: -$margin_small_v * 2;
    }

    .infoContent {
        width: 60%;
    }

    .functionsHead {
        @include p1;
        font-weight: 350;

        margin-top: -2vh;
        margin-bottom: $margin_medium_v;
    }

    .descriptionText {
        width: 85%;

        @include p2;
        font-size: 0.9rem;
        font-weight: 200;
        line-height: 0.9rem * 1.8;
    }

    .imgHeader {
        width: 250px;
        height: 450px;
    }
}

@media (min-width: 1200px) {
    .homePageContainer {
        justify-content: space-between;
    }

    .headTitle {
        @include h1;
    }

    .functionsHead {
        @include h4;
        font-weight: 350;

        margin-bottom: $margin_medium_v * 1.5;
    }

    .descriptionText {
        @include p1;
        font-size: 0.95rem;
        line-height: 0.95rem * 1.8;
        font-weight: 300;
    }

    .imgHeader {
        width: 400px;
        height: 500px;

        margin-right: $margin_medium_h - 1vw;
    }
}
