@import './styles/Enums.styles.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: $white;
    }

    ::-webkit-scrollbar-thumb {
        background: $red;
        -webkit-border-radius: 1ex;

        &:hover {
            background: $blue;
        }
    }

    ::-webkit-scrollbar-corner {
        background: $white;
    }
}

html {
    scroll-snap-type: y mandatory;

    font-size: 16px;
}

body {
    width: 100vw;
    overflow-x: hidden;

    background-color: $white;

    @include p1;
}

body:before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-image: url('./assets/Hannelore_Background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;

    z-index: -1;
}

a {
    text-decoration: none;
    color: $black;
}

// #app {
//     position: relative;
//     min-height: 100vh;
// }

// #contentWrap {
//     padding-bottom: 2.5rem; /* Footer height */
// }

// #footer {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     height: 2.5rem; /* Footer height */
// }

/***MEDIA QUERIES***/
@media (min-width: 940px) {
    body:before {
        opacity: 0.4;

        z-index: -1;
    }
}
