@import '../Enums.styles.scss';

.contactSection {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    padding: $margin_medium_v * 2 $margin_medium_h * 2;
    padding-bottom: 0;

    color: $white;
    background-color: $black;
}

.contactInfoContainer {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.info {
    display: flex;
    flex-direction: column;

    & h2 {
        @include p2;
        margin-bottom: $margin_small_v * 2;
    }

    & a {
        width: fit-content;

        border-bottom: 1px solid $white;
        color: $white;
    }
}

.getInTouch {
    display: flex;
    flex-direction: column;

    & h2 {
        @include p2;
        margin-bottom: $margin_small_v * 2;
    }

    & a {
        width: fit-content;

        border-bottom: 1px solid $white;
        color: $white;

        margin-bottom: $margin_small_v;
    }
}

.footerText {
    width: 100%;
    margin-top: $margin_medium_v * 2;

    @include h3;
}

/***MEDIA QUERIES***/
@media (min-width: 940px) {
    .contactSection {
        padding-top: $margin_large_v;
    }

    .contactInfoContainer {
        width: 100vw;
        height: 40%;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        margin-bottom: 0;
    }

    .info {
        width: 30%;
        margin-top: $margin_medium_v * 2;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        & h2 {
            @include p2;
            margin-bottom: $margin_small_v * 2;
        }

        & a {
            width: fit-content;

            border-bottom: 1px solid $white;
            color: $white;

            margin-bottom: $margin_small_v;
        }
    }

    .getInTouch {
        width: 30%;
        margin-top: $margin_medium_v * 2;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        & h2 {
            @include p2;
            margin-bottom: $margin_small_v * 2;
        }

        & a {
            width: fit-content;

            border-bottom: 1px solid $white;
            color: $white;

            margin-bottom: $margin_small_v;
        }
    }

    .footerText {
        width: 100%;
        height: 50%;
        text-align: center;

        margin: 0;

        @include h1;
        font-size: 5rem;
        line-height: 5rem * 1.3;

        margin-top: $margin_large_v;
    }
}
