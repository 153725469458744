@import '../Enums.styles.scss';

.aboutSection {
    width: 100%;
    height: 100%;

    padding: 0 $margin_medium_h * 2;
    padding-top: $margin_large_v;
    margin-bottom: $margin_large_v * 1.5;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.experienceTitleDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: fit-content;
}

.experienceTitle {
    margin: 0;

    @include h3;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 2.7rem * 1.3;
    color: $black;

    margin-bottom: $margin_medium_v * 2;
}

.experienceContentContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
}

.yearExperienceContainer {
    border: 1px solid $grey;
    border-radius: 50px;

    margin-bottom: $margin_medium_v - 1vh;

    width: fit-content;
}

.yearExperience {
    @include sora-font;
    font-size: 0.9rem;
    font-weight: 300;

    color: $grey;
    padding: $margin_small_v $margin_medium_h * 1.2;
}

.leftSectionExperience {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.titleExperience {
    @include h3;
    font-size: 2rem;

    margin-bottom: -$margin_small_v;
}

.functionExperience {
    margin-top: 0;
}

.experienceItem {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/***REFERENCES***/
.skillsReferenceSection .experienceTitle {
    padding-top: $margin_large_v;
    margin-bottom: $margin_medium_v;
}

.titleSectionReference {
    @include h3;
    font-size: 2rem;
    line-height: 2rem * 1.3;
    font-weight: 400;
}

.spanReference {
    @include p1;

    margin-bottom: $margin_small_v;
}

/***MEDIA QUERIES***/
@media screen and (min-width: 940px) {
    .aboutSection {
        margin-top: $margin_large_v;
    }

    .experienceTitle {
        @include h1;
        margin-bottom: $margin_medium_v * 3;
    }

    .experienceContentContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .titleSectionExperience {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;

        width: 38vw;
    }

    .yearExperience {
        @include p2;

        padding: $margin_small_v * 1.5 $margin_small_h * 1.5;
    }

    .titleExperience {
        margin-top: 0;
    }

    .leftSectionExperience {
        width: 70%;
    }

    .textExperience {
        max-width: 75%;
    }

    .underlineSkillsContainer {
        width: 1px;
        height: 100%;

        margin: 0 $margin_small_h * 2;

        background-color: rgba($color: $grey, $alpha: 0.5);
    }

    /***REFERENCES***/
    .skillsReferenceSection .experienceTitle {
        padding-top: $margin_large_v * 1.5;
        margin-bottom: $margin_medium_v;
    }
}
