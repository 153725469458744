/***KEYFRAMES***/
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/***TRANSITIONS***/
$globalTransition: all 0.4s ease-in-out;
$colorTransition: color 0.4s ease-in-out;